import React, { useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import ProfileSelect from '../../components/auth/ProfileSelect';
import { useDispatch } from 'react-redux';
import { resetStudentStates } from 'features/userProfile/userSlice';
import { useNavigate } from 'react-router-dom';
import { useActions } from 'hooks/useActions';

export default function ChooseUser(): JSX.Element {
  const dispatch = useDispatch();
  const { setSkinChoice } = useActions();
  const navigate = useNavigate();
  const redirectToEditProfile = () => {
    navigate('/account/edit-profiles');
  };

  const handleLogout = () => {
    setSkinChoice(null);
    navigate('/logout');
  };

  useEffect(() => {
    dispatch(resetStudentStates());
  }, [dispatch]);

  return (
    <>
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'brand.800'}
        bgGradient={'linear(brand.700, brand.800)'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
      ></Box>
      <Button
        position='fixed'
        top='0'
        right='0'
        m='16px'
        onClick={() => redirectToEditProfile()}
      >
        EDIT PROFILE
      </Button>
      <Button
        position='fixed'
        top='60px'
        right='0'
        m='16px'
        onClick={() => handleLogout()}
      >
        LOG OUT
      </Button>
      <ProfileSelect mode={'select'} />
    </>
  );
}
